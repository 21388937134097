import type { ListboxProps } from '@headlessui/react';
import { Check, CaretDown, Info } from '@phosphor-icons/react';
import { colors } from '@shiftsmartinc/style-guide';
import { ElementType, Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Tooltip } from 'react-tooltip';
import classnames from 'classnames';
import { Errors } from '../components/Errors';
import { BaseInput } from '../types';

export interface SelectMultipleOption {
  extra?: string;
  icon?: JSX.Element;
  name: string;
  value: string;
}

export type SelectMultipleBase = BaseInput & {
  className?: string;
  disabled?: boolean;
  options: SelectMultipleOption[];
  placeholder?: string;
  tooltip?: string;
};

export type SelectMultipleListboxProps = ListboxProps<
  ElementType,
  SelectMultipleOption[],
  SelectMultipleOption[]
>;

export type SelectMultipleProps = {
  defaultValue?: SelectMultipleOption[];
  onBlur?: SelectMultipleListboxProps['onBlur'];
  onChange?: SelectMultipleListboxProps['onChange'];
  textValue?: string;
  value?: SelectMultipleOption[];
} & SelectMultipleBase;

/**
 * @name SelectMultiple
 * @description A select dropdown that allows multiple selections
 */
export const SelectMultiple = (props: SelectMultipleProps) => {
  const {
    className,
    defaultValue,
    errors,
    id,
    label,
    onChange,
    options,
    placeholder = 'Select an option',
    textValue,
    tooltip,
    ...rest
  } = props;

  // Hooks
  const [selected, setSelected] = useState<SelectMultipleOption[]>(
    defaultValue ?? [],
  );

  // Setup
  const isSelected = selected.length > 0;

  // Handlers
  const onChangeHandler = (value: SelectMultipleProps['value']) => {
    setSelected(value as SelectMultipleOption[]);
    if (onChange) onChange(value as any);
  };

  // Markup
  const getLabel = () => {
    if (textValue) return textValue;
    return selected.length > 0
      ? selected.map((option) => option.name).join(', ')
      : placeholder;
  };

  const getValue = () => {
    return selected.map((option) => option.value);
  };

  // Life Cycle
  useEffect(() => {
    setSelected(defaultValue ?? []);
  }, [defaultValue]);

  // 🔌 Short Circuits
  return (
    <div className={className}>
      {/* The UI Component */}
      <Listbox
        {...rest}
        by="value"
        multiple={true}
        onChange={onChangeHandler}
        value={selected}
      >
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="font-500 text-gray-1 flex-start mb-2 flex items-center gap-1 text-sm leading-6">
                {tooltip && (
                  <span
                    data-tooltip-content={tooltip}
                    data-tooltip-id={`${id}-tooltip`}
                  >
                    <Info color={colors.gray[5]} size={18} />
                    <Tooltip id={`${id}-tooltip`} place="top" />
                  </span>
                )}

                {label}

                {isSelected && (
                  <span
                    className="text-gray-5 text-xs"
                    onClick={() => {
                      onChangeHandler([]);
                    }}
                  >
                    Clear
                  </span>
                )}
              </Listbox.Label>
            )}

            <div className="relative">
              <Listbox.Button
                className={classnames(
                  'background-main',
                  'text-gray-1 focus:ring-brand relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left',
                  'shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 sm:text-sm sm:leading-6',
                  {
                    'ring-error': !!errors,
                    'ring-gray-7 ': !errors,
                  },
                )}
              >
                <span
                  className={classnames('block truncate', {
                    'text-gray-1': isSelected,
                    'text-gray-5': !isSelected,
                  })}
                >
                  {getLabel()}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <CaretDown
                    aria-hidden="true"
                    className={classnames(
                      'h-5 w-5',
                      errors ? 'text-error' : 'text-gray-4',
                    )}
                  />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                <Listbox.Options className="background-main absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      className={({ active, selected }) =>
                        classnames(
                          active || selected
                            ? 'bg-brand text-white'
                            : 'text-gray-1',
                          'relative cursor-pointer select-none py-2 pl-3 pr-9',
                        )
                      }
                      key={option.value}
                      value={option}
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classnames(
                              active || selected ? 'font-700' : 'font-normal',
                              'flex items-center gap-2 truncate',
                            )}
                          >
                            {option.icon}
                            {option.name}
                            <span className="text-gray-5 text-xs">
                              {option.extra}
                            </span>
                          </span>

                          {(active || selected) && (
                            <span
                              className={classnames(
                                selected ? 'text-white' : 'text-brand',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <Check aria-hidden="true" className="h-5 w-5" />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>

      {/* Store the value in a readonly, hidden input */}
      <input
        data-testid={id}
        name={id}
        readOnly={true}
        type="hidden"
        value={getValue()}
      />

      {/* Render any errors */}
      {errors && <Errors className="text-error mt-2" errors={errors} id={id} />}
    </div>
  );
};
