import { TagProps } from '../components/Tag';

/**
 * @name getTagStyles
 * @description tbd...
 */
export const getTagStyles = (color: TagProps['color'], isPill: boolean) => {
  /**
   * @description Brand colors
   */
  if (color === 'asphalt') {
    if (!isPill) return 'text-asphalt';
    return 'text-asphalt-dark bg-asphalt-lighter';
  }

  if (color === 'blue' || color === 'brand') {
    if (!isPill) return 'text-brand';
    return 'text-brand bg-brand-lighter';
  }

  if (color === 'lime') {
    if (!isPill) return 'text-lime';
    return 'text-teal-dark bg-lime-light';
  }

  if (color === 'orange') {
    if (!isPill) return 'text-orange';
    return 'text-orange-dark bg-orange-lighter';
  }

  if (color === 'purple') {
    if (!isPill) return 'text-purple';
    return 'text-purple bg-purple-lighter';
  }

  if (color === 'red') {
    if (!isPill) return 'text-red';
    return 'text-red-dark bg-red-lighter';
  }

  if (color === 'teal') {
    if (!isPill) return 'text-teal';
    return 'text-teal-dark bg-teal-lighter';
  }

  if (color === 'violet') {
    if (!isPill) return 'text-violet';
    return 'text-violet-dark bg-violet-lighter';
  }

  if (color === 'yellow') {
    if (!isPill) return 'text-yellow-lighter';
    return 'text-yellow-dark bg-yellow-light';
  }

  /**
   * @description Disabled state
   */
  if (color === 'disabled') {
    if (!isPill) return 'text-gray-6';
    return 'text-gray-6 bg-gray-8';
  }

  /**
   * @description Sentiment colors
   */
  if (color === 'info') {
    if (!isPill) return 'text-info';
    return 'text-white bg-info';
  }

  if (color === 'success') {
    if (!isPill) return 'text-success';
    return 'text-white bg-success';
  }

  if (color === 'warning') {
    if (!isPill) return 'text-warning';
    return 'text-warning-darker bg-warning';
  }

  if (color === 'error') {
    if (!isPill) return 'text-error';
    return 'text-white bg-error';
  }

  /**
   * @description We treat "brand" as the default/fallback color
   */
  if (!isPill) return 'text-brand-lighter';

  return 'text-brand bg-brand-lighter';
};
