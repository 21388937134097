import * as React from 'react';
import classnames from 'classnames';
import { ReactElement } from 'react';
import {
  Bell,
  Bug,
  CheckCircle,
  WarningCircle,
  Info,
  X,
} from '@phosphor-icons/react';

export interface AlertProps {
  accent?: boolean;
  children: ReactElement | ReactElement[];
  className?: string;
  title: string | ReactElement;
  type?: 'alert' | 'debug' | 'error' | 'info' | 'success' | 'warning';
}

/**
 * @name Alert
 * @description TODO: Add a description
 */
export const Alert = (props: AlertProps) => {
  const { accent = true, children, className, title, type = 'error' } = props;

  // Hooks

  // Setup
  const Icons: Record<AlertProps['type'] & string, React.ReactNode> = {
    alert: <Bell aria-hidden="true" className="h-5 w-5 text-white" />,
    debug: <Bug aria-hidden="true" className="text-brand h-5 w-5" />,
    error: <X aria-hidden="true" className="text-red h-5 w-5" />,
    info: <Info aria-hidden="true" className="text-brand h-5 w-5" />,
    success: (
      <CheckCircle aria-hidden="true" className="text-lime-dark h-5 w-5" />
    ),
    warning: (
      <WarningCircle aria-hidden="true" className="text-yellow h-5 w-5" />
    ),
  };

  const icon = Icons[type];

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'rounded-md p-4',
        {
          'bl-solid border-l-4': accent,
          'border-l-brand bg-brand-lighter': accent && type === 'info',
          'border-l-brand bg-gray-9': accent && type === 'debug',
          'border-l-lime bg-lime-lighter': accent && type === 'success',
          'border-l-red bg-red-lighter': accent && type === 'error',
          'border-l-red-darker bg-red': accent && type === 'alert',
          'border-l-yellow bg-yellow-lighter': accent && type === 'warning',
        },
        className,
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          <h3
            className={classnames('font-700 mb-4 text-sm', {
              'text-brand': type === 'debug',
              'text-brand-dark': type === 'info',
              'text-lime-darker': type === 'success',
              'text-red': type === 'error',
              'text-white': type === 'alert',
              'text-yellow-dark': type === 'warning',
            })}
          >
            {title}
          </h3>

          <div
            className={classnames({
              'text-brand': type === 'debug',
              'text-brand-dark': type === 'info',
              'text-lime-darker': type === 'success',
              'text-red': type === 'error',
              'text-white': type === 'alert',
              'text-yellow-dark': type === 'warning',
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
