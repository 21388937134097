import classnames from 'classnames';
import { LabelHTMLAttributes, ReactElement } from 'react';

export interface LabelProps
  extends Omit<LabelHTMLAttributes<HTMLLabelElement>, 'htmlFor'> {
  children?: ReactElement | ReactElement[] | string;
  className?: string;
  id: string;
}

/**
 * @name Label
 * @description TODO: Add a description
 */
export const Label = (props: LabelProps) => {
  const { className, children, id, ...rest } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <label
      className={classnames(
        'text-gray-1 font-500 mb-1 block text-sm leading-6',
        className,
      )}
      htmlFor={id}
      {...rest}
    >
      {children}
    </label>
  );
};
