import classnames from 'classnames';

export interface RadioGroupProps {
  className?: string;
}

/**
 * @name RadioGroup
 * @description TODO: Add a description
 */
export const RadioGroup = (props: RadioGroupProps) => {
  const { className } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={classnames('p-4', className)}>
      <h2>RadioGroup</h2>
    </div>
  );
};
