import * as React from 'react';
import { WarningCircle } from '@phosphor-icons/react';
import classnames from 'classnames';
import { InputHTMLAttributes } from 'react';
import { BaseInput } from '../types';
import { Errors } from './Errors';
import { Label } from './Label';

export interface InputProps
  extends BaseInput,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'name' | 'type'> {
  classNameInput?: string;
  leftIcon?: JSX.Element;
  type?: Exclude<InputHTMLAttributes<HTMLInputElement>['type'], 'checkbox'>;
  unit?: string;
}

/**
 * @name Input
 * @description TODO: Add a description
 */
export const Input = (props: InputProps) => {
  const {
    autoComplete = 'off',
    className,
    classNameInput,
    id,
    leftIcon,
    errors,
    label,
    onChange,
    type = 'text',
    unit,
    ...rest
  } = props;

  // Hooks

  // Setup
  const isInvalid = !!errors;
  const isRange = type === 'range';
  const showErrors = isInvalid;

  // Handlers
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={className}>
      {/* Render a label if we have one */}
      {!!label && (
        <Label className="mb-2" id={id}>
          {label}
        </Label>
      )}

      {/* Now our input element */}
      <div className="relative">
        {leftIcon}
        <input
          aria-describedby={`${id}-error`}
          aria-invalid={!!errors}
          aria-label={id}
          autoComplete={autoComplete}
          className={classnames(
            'ring-gray-7 placeholder:text-gray-5 block w-full rounded-md border-0 px-3 py-1.5 sm:text-sm sm:leading-6',
            {
              'ring-1 ring-inset focus:ring-2 focus:ring-inset ': !isRange,
              'text-red ring-red/80 focus:ring-red pr-10': showErrors,
            },
            classNameInput,
          )}
          id={id}
          name={id}
          onChange={onChangeHandler}
          type={type}
          {...rest}
        />
        {Boolean(unit) && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
            <span className="text-gray-5">{unit}</span>
          </div>
        )}
        {showErrors && type !== 'hidden' && !unit && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <WarningCircle aria-hidden="true" className="text-red h-5 w-5" />
          </div>
        )}
      </div>

      {/* And any error(s) that exist */}
      {showErrors && (
        <Errors
          className="text-red m-0 mt-2"
          errors={errors}
          id={`${id}-error`}
        />
      )}
    </div>
  );
};
